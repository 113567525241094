<template>
  <div class="relative h-full" :class="externalChat ? '' : 'w-80 border-r border-gray-600'">
    <div class="flex items-center justify-between bg-gray-800 px-3 py-2" v-if="!externalChat">
      <span class="text-xs font-medium leading-4 tracking-wide">Chats</span>
      <div class="flex items-center space-x-2.5">
        <MagnifyingGlassIcon
          @click="showSearchModal = true"
          class="mr-1.5 inline h-4 w-4 cursor-pointer text-gray-400 hover:text-white"
        />
        <PencilSquareIcon
          @click="showCreateModal = true"
          class="h-4 w-4 cursor-pointer text-gray-400 hover:text-white"
        />
      </div>
    </div>
    <div id="chat-sidebar" class="flex h-full flex-col overflow-y-hidden rounded">
      <ChatSidebarChannelGroup
        type="requests"
        :channels="requestChannels"
        :chosen-channel="chosenChannel"
        :external="externalChat"
        :request-opened="requestOpened"
        @show-search-modal="onShowSearchModal()"
        @set-requests-state="requestOpened = !requestOpened"
      />
      <ChatSidebarChannelGroup
        :type="selectedFolder"
        :selected-folder="selectedFolder"
        :channels="filteredChannels"
        :all-channels="channels"
        :chosen-channel="chosenChannel"
        :external="externalChat"
        :request-opened="requestOpened"
        @set-folder="selectedFolder = $event"
      />
    </div>
    <div
      class="fixed inset-x-0 bottom-0 flex h-14 w-80 items-center justify-between space-x-1 bg-gray-800 px-3"
      v-if="!externalChat"
    >
      <BaseUser
        v-if="streamUser"
        :user="streamUser"
        :title="streamUser.name"
        :max-text-length="30"
        :sub-text="getUserSubtitle(streamUser)"
        :hide-cursor="true"
      >
        <template #image>
          <ChatImage size="sm" :show-online="true" :item="streamUser"> </ChatImage>
        </template>
      </BaseUser>
      <div class="flex items-center space-x-2.5">
        <div class="cursor-pointer" @click="$emitter.$emit('channel:change', null)">
          <HomeIcon class="h-4 w-4 text-gray-400 hover:text-white" />
        </div>
        <div class="cursor-pointer" @click="showSettingsModal = true">
          <Cog8ToothIcon class="h-4 w-4 text-gray-400 hover:text-white" />
        </div>
      </div>
    </div>
    <ChatCreateChannelModal
      v-if="showCreateModal"
      :channels="channels"
      :searched-term="searchedTerm"
      :searched-user="searchedUser"
      @close="
        showCreateModal = false;
        searchedTerm = null;
      "
    />
    <ChatSearchModal
      v-if="showSearchModal"
      :channels="channels"
      :pinned-channels="pinnedChannels"
      :temporary-channels="temporaryChannels"
      :archived-channels="archivedChannels"
      :search-tab="searchTab"
      @close="showSearchModal = false"
      @find-message-in-channel="searchMessage($event)"
      @find-channel="
        showSearchModal = false;
        $emitter.$emit('channel:change', $event);
      "
    />
    <ChatSettingsModal v-if="showSettingsModal" @close="showSettingsModal = false" />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue';
import ChatSettingsModal from './ChatSettingsModal.vue';
import ChatSidebarChannelGroup from './ChatSidebarChannelGroup.vue';
import BaseUser from './ChatBaseUser.vue';
import ChatImage from './ChatImage.vue';
import ChatCreateChannelModal from './ChatCreateChannelModal.vue';
import ChatSearchModal from './ChatSearchModal.vue';
import { getUserSubtitle } from '@/composeables/chat';
import { useMapGetter } from '@/store/map-state';
import { PencilSquareIcon, Cog8ToothIcon, HomeIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { useStore } from 'vuex';
import useHttp from '@/composeables/http';
import useEmitter from '@/composeables/emitter';

const { client, streamUser, folders, rejectedChannels } = useMapGetter();
const $store = useStore();
const $http = useHttp();
const $emitter = useEmitter();
const $emit = defineEmits(['message:search']);

const props = defineProps({
  channels: { type: Array, default: () => [] },
  chosenChannel: Object,
  showRequests: Boolean,
  externalChat: { type: Boolean, default: false }
});

// MODAL
const showSearchModal = ref(false);
onMounted(() => {
  $emitter.$on('show:user-bio', user => {
    showSearchModal.value = false;
  });
});

function searchMessage(payload) {
  $emit('message:search', payload);
  showSearchModal.value = false;
}

const searchTab = ref(null);
function onShowSearchModal() {
  searchTab.value = 'people';
  showSearchModal.value = true;
  $emitter.$emit('toggle-chat-dropdown', false);
}

watch(
  () => showSearchModal.value,
  () => {
    if (showSearchModal.value == false) {
      searchTab.value = null;
    }
  }
);

const showSettingsModal = ref(false);
onMounted(() => {
  $emitter.$on('show:settings-modal', () => {
    showSettingsModal.value = true;
  });
});

// SCROLL VISIBLE
const scrollVisible = ref(false);
onMounted(() => {
  const elm = document.getElementById('chat-sidebar');
  if (ResizeObserver) {
    new ResizeObserver(() => {
      scrollVisible.value = elm.scrollHeight > elm.clientHeight ? true : false;
    }).observe(elm);
  }
});

// REQUEST OPENED
const selectedFolder = ref('all_chats');
const requestOpened = ref(false);
onMounted(() => {
  if (props.chosenChannel && requestChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
    requestOpened.value = true;
  } else if (props.chosenChannel && archivedChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
    selectedFolder.value = 'archived';
  }
});

watch(
  () => props.chosenChannel,
  () => {
    if (props.chosenChannel && requestChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
      requestOpened.value = true;
    } else if (props.chosenChannel) {
      requestOpened.value = false;
    }
  }
);

// EMITTERS
const searchedTerm = ref(null);
const searchedUser = ref(null);
onMounted(() => {
  $emitter.$on('channel:added', async channel => {
    let selectedFolderItem = folders.value.find(x => x.id == selectedFolder.value);
    if (selectedFolderItem) {
      selectedFolderItem.channels.push(channel.cid);
      await $http.put(`/messaging_folders/${selectedFolderItem.folder_id}`, {
        messaging_folder: {
          name: selectedFolderItem.text,
          channels: selectedFolderItem.channels
        }
      });
      const res = await $http.get('/messaging_folders');
      $store.dispatch('setFolders', res.data.folders);
    }
  });
  $emitter.$on('show:create-channel-modal', event => {
    const userCompanies = streamUser.value.companies_data?.map(c => c.name) || [];
    if (event != undefined && typeof event === 'object') {
      if (userCompanies && userCompanies.includes(event.companies_data[0]?.name)) {
        searchedUser.value = event;
      } else {
        searchedTerm.value = event.companies_data[0]?.name;
        searchedUser.value = event;
      }
    } else if (event != undefined && (!userCompanies || !userCompanies.includes(event))) {
      searchedTerm.value = event;
    }
    showCreateModal.value = true;
  });
});

// CHANNELS
const pinnedChannels = computed(() => {
  return props.channels.filter(c =>
    streamUser.value && Array.isArray(streamUser.value.pinned_channels)
      ? streamUser.value.pinned_channels.includes(c.cid)
      : false
  );
});

const unreadChannels = computed(() => {
  let unreadChannels = props.channels.filter(
    c =>
      !requestChannels.value.map(x => x.cid).includes(c.cid) &&
      !rejectedChannels.value.includes(c.cid) &&
      !archivedChannels.value.map(x => x.cid).includes(c.cid) &&
      c.state.unreadCount > 0
  );

  return unreadChannels;
});

const allChatChannels = computed(() => {
  let allChats = props.channels.filter(
    c =>
      !requestChannels.value.map(x => x.cid).includes(c.cid) &&
      !rejectedChannels.value.includes(c.cid) &&
      !archivedChannels.value.map(x => x.cid).includes(c.cid)
  );

  return allChats;
});

const archivedChannels = computed(() => {
  let archivedChats = props.channels.filter(c =>
    streamUser.value && Array.isArray(streamUser.value.archive_channels)
      ? streamUser.value.archive_channels.includes(c.cid)
      : false
  );

  return archivedChats;
});

const requestChannels = computed(() => {
  var requests = props.channels.filter(c =>
    Object.values(c.state.members).length > 0
      ? Object.values(c.state.members).some(
          u =>
            u.user_id === client.value.user.id && u.invited === true && !u.invite_accepted_at && !u.invite_rejected_at
        )
      : false
  );
  $store.dispatch(
    'setRequestChannels',
    requests.map(x => x.cid)
  );
  return requests;
});

const filteredChannels = computed(() => {
  let channelsData = [];
  if (selectedFolder.value === 'all_chats') {
    channelsData = allChatChannels.value;
  } else if (selectedFolder.value === 'unread') {
    channelsData = unreadChannels.value;
  } else if (selectedFolder.value === 'requests') {
    channelsData = requestChannels.value;
  } else if (selectedFolder.value === 'archived') {
    channelsData = archivedChannels.value;
  } else {
    let selectedFolderData = folders.value.find(x => x.id == selectedFolder.value);
    if (selectedFolderData) {
      channelsData = props.channels.filter(
        c => selectedFolderData.channels.includes(c.cid) && !archivedChannels.value.map(x => x.cid).includes(c.cid)
      );
    }
  }
  return channelsData;
});

// FOLDERS
async function addToFolder(data) {
  let folder = folders.value.find(f => f.id === data.id);
  const response = await $http.put(`/messaging_folders/${data.folder_id}`, {
    messaging_folder: {
      channels: [...folder.channels, data.channelCid]
    }
  });
  $store.dispatch('updateFolder', response.data.folder);
}

onMounted(() => {
  $emitter.$on('add-to-folder', data => {
    addToFolder(data);
  });
});

watch(
  () => folders.value,
  (newVal, oldVal) => {
    if (oldVal.length > 0) {
      let newFolder = newVal.filter(x => !oldVal.map(o => o.id).includes(x.id));
      if (newFolder.length) {
        selectedFolder.value = newFolder[0].id;
      }
    }
  }
);

// OTHERS
const showCreateModal = ref(false);
onBeforeUnmount(() => {
  $emitter.$off('show:user-bio');
  $emitter.$off('show:create-channel-modal');
  $emitter.$off('show:settings-modal');
  $emitter.$off('add-to-folder');
});
</script>
